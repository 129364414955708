<template>
  <PageContent>
    <div class="box is-12 gradient-bg">
      <div class="welcome-to-homeowner pb-4">
        <div class="text-style-1">Welcome to</div>
        <div>Homeowner Setup</div>
      </div>
      <ValidationObserver ref="form">
        <transition name="fade" mode="out-in" appear>
          <div class="items-center">
            <form @submit.prevent="onFormSubmit" class="form box white-box text-left">
              <div class="step-1-find-your-cod">
                <span class="text-style-1">Step 2</span>
                <div>Enter your code</div>
              </div>
              <div class="if-you-cannot-find-y force-left">
                If you cannot find your setup code or didn’t receive your billing statement, please
                email <span class="text-style-1">support@hoamyway.com</span> for help.
              </div>
              <fieldset>
                <div class="form-grid force-left">
                  <div class="column is-6">
                    <valid-input
                      label="Enter Your Code Below"
                      type="text"
                      vid="setupcode"
                      placeholder="Setup Code"
                      rules="required|max:20"
                      v-model="formData.ownerSetupCode"
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="terms" rules="required" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
              <fieldset>
                <button type="submit" class="button is-primary is-rounded">
                  Submit Code Now >
                </button>
              </fieldset>
            </form>
          </div>
        </transition>
      </ValidationObserver>
      <div class="image-padding">
        <img
          class="hero-image"
          height="100%"
          width="100%"
          style="min-height: 200px;"
          :src="require('@/assets/images/onboarding-couch.png')"
        />
      </div>
    </div>
  </PageContent>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import PageContent from '@/containers/PageContent'
import { ToastProgrammatic as Toast } from 'buefy'
import { mapState } from 'vuex'
import registration from '@/services/registration'

import ValidInput from '@/components/inputs/ValidInput'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

let DEFAULT_FORM_VALUES = {
  ownerSetupCode: ''
}

export default {
  components: {
    PageContent,
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      isDebug: true,
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      }
    }
  },

  async mounted() {
    this.resetForm()
  },

  watch: {
    hoaId() {
      this.resetForm()
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  methods: {
    resetForm() {
      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    },

    async onFormSubmit() {
      try {
        if (
          !this.formData ||
          this.formData == undefined ||
          !this.formData.ownerSetupCode ||
          this.formData.ownerSetupCode == undefined
        ) {
          notifyError(`Please enter a proper sign-up code for proper processing.`)
          return
        }

        try {
          let results = await registration.ValidateOwnerSignupCodeWithSuccess(
            this.formData.ownerSetupCode
          )

          if (results && results != undefined) {
            if (this.isDebug == true) console.debug('results...' + JSON.stringify(results))

            if (results == false) {
              notifyError(`The sign-up code is not valid.`)
              return
            }
          } else {
            notifyError(`The sign-up code is not valid.`)
            return
          }
        } catch (e) {
          notifyError(`The sign-up code is not valid.`)
          return
        }

        // redirect to registration with sign-up code
        this.formComplete = true
        this.$router.push({
          name: 'registerHomeowner',
          params: { code: this.formData.ownerSetupCode }
        })
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }
        notifyError(e)
      }
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.if-you-cannot-find-y {
  margin: 16px 0 31px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #838383;
}

.if-you-cannot-find-y .text-style-1 {
  color: #2cabe1;
}

.gradient-bg {
  width: 100%;
  height: 100%;
  margin: 0 2px 0 0;
  padding: 100 203.4px 0 0;
  background-image: linear-gradient(126deg, #2662d6 2%, #133a86 99%);
}

.image-padding {
  padding-top: 40px !important;
  padding-left: 290px !important;
}

.rectangle {
  margin: 15.8px 0 0;
  padding: 32.2px 101.6px 285px 169px;
  transform: rotate(0deg);
  mix-blend-mode: screen;
  border-radius: 430px;
  background-color: #1a47b0;
}

.welcome-to-homeowner {
  width: auto;
  height: auto;
  font-family: Helvetica;
  font-size: 39px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: normal;
  text-align: center !important;
  color: #fff;
}

.welcome-to-homeowner .text-style-1 {
  font-size: 31px;
  font-weight: normal;
  color: #2cabe1;
  text-align: center !important;
}

.step-1-find-your-cod {
  text-align: left;
  margin: 0 0 16px 0;
  font-family: Helvetica;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: #112d6f;
}

.step-1-find-your-cod .text-style-1 {
  font-size: 26px;
  font-weight: normal;
  color: #2cabe1;
}

.white-box {
  width: 90%;
  max-width: 660px;
  height: auto;
  text-align: center !important;
  float: center !important;
  background-position: center;
}

.items-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.hero-image {
  display: none;
}

@media screen and (min-width: 1024px) {
  .hero-image {
    display: block;
  }
}
</style>
